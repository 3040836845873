html {
  box-sizing: border-box;
  background-color: #000;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}
